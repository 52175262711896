import React from 'react';
import { Link } from 'react-router-dom';
import { FaNewspaper, FaSearch, FaHeadphones, FaStethoscope, FaUserMd } from 'react-icons/fa';

const CarerLabPage: React.FC = () => {
  return (
    <div className="h-full w-full flex flex-col items-center justify-center bg-gray-100">
      
      <div className="w-full bg-white p-8 rounded-xl shadow-md space-y-12">
        <h1 className="text-4xl font-bold text-center text-gray-800">CarerLab</h1>

        <p className="text-xl text-blue-500 font-medium text-left mx-10">
          Caresnippet is your essential daily companion, designed to keep doctors updated, informed, and engaged with the latest in healthcare and research.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 text-center">Key Features:</h2>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mx-6 md:mx-10">
        
          <div className="flex items-center bg-gray-100 p-6 rounded-xl shadow-lg border-2">
            <FaNewspaper className="text-blue-500 text-4xl mr-4" />
            <div>
              <h3 className="text-xl font-semibold">Daily Digest on WhatsApp</h3>
              <p>Receive a personalized morning summary of the latest journal articles and healthcare news in your specialty, delivered directly to your WhatsApp.</p>
            </div>
          </div>

    
          <div className="flex items-center bg-gray-100 p-6 rounded-xl shadow-lg border">
            <FaSearch className="text-green-500 text-4xl mr-4" />
            <div>
              <h3 className="text-xl font-semibold">Interactive Conversations</h3>
              <p>Request detailed journal insights or search for articles, providing on-demand access to knowledge you need.</p>
            </div>
          </div>

        
          <div className="flex items-center bg-gray-100 p-6 rounded-xl shadow-lg border">
            <FaHeadphones className="text-purple-500 text-4xl mr-4" />
            <div>
              <h3 className="text-xl font-semibold">Audio Format for Content</h3>
              <p>Listen to journal summaries and healthcare news on the go with audio formats available.</p>
            </div>
          </div>

          
          <div className="flex items-center bg-gray-100 p-6 rounded-xl shadow-lg border">
            <FaStethoscope className="text-red-500 text-4xl mr-4" />
            <div>
              <h3 className="text-xl font-semibold">Specialized Content Across 15+ Areas</h3>
              <p>Journals are selected from over 15 medical specialties and ranked based on their impact factors for quality and relevance.</p>
            </div>
          </div>

      
          <div className="flex items-center bg-gray-100 p-6 rounded-xl shadow-lg border">
            <FaUserMd className="text-yellow-500 text-4xl mr-4" />
            <div>
              <h3 className="text-xl font-semibold">Personalized and Curated Content</h3>
              <p>Stay updated with research tailored specifically to your field and your needs.</p>
            </div>
          </div>
        </div>

        <p className="text-lg text-blue-700 text-left mt-8 ml-8 md:ml-10">
          Caresnippet is designed to save you time, boost productivity, and enhance your medical expertise—all at your fingertips.
        </p>

        {/* <div className="mt-8 text-center">
          <Link to="/" className="inline-block px-6 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition">
            Back to Home
          </Link>
        </div> */}
      </div>
    </div>
  );
};

export default CarerLabPage;
