import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";

const Navbar: React.FC = () => {
  const [isProductDropdownOpen, setIsProductDropdownOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const productRef = useRef<HTMLLIElement>(null);

  const toggleProductDropdown = () => {
    setIsProductDropdownOpen((prev) => !prev);
  };

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      productRef.current &&
      !productRef.current.contains(event.target as Node)
    ) {
      setIsProductDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <nav className="bg-gray-50 shadow-md">
      <div className="container mx-auto px-6 py-3 flex justify-between items-center">
        <div className="ml-0   md:ml-10">
        <img src="carer.png" alt="Carer logo"  className="h-8"/>
        </div>
        <button
          className="md:hidden focus:outline-none"
          onClick={toggleMenu}
        >
          {isMenuOpen ? <FaTimes /> : <FaBars />}
        </button>
        <ul
          className={`md:flex space-x-8 ${
            isMenuOpen ? "flex flex-col absolute top-20 rounded-md right-0 bg-white px-6 shadow-lg mt-[-25px]" : "hidden"
          }`}
        >
          <li className="text-gray-900 hover:text-blue-600 ml-8">
            <Link to="/">Home</Link>
          </li>
          <li
            ref={productRef}
            className="relative text-gray-900 py-2 md:py-0"
          >
            <button
              onClick={toggleProductDropdown}
              className="focus:outline-none hover:text-blue-600"
            >
              Product
            </button>
            {/* Dropdown Menu */}
            {isProductDropdownOpen && (
              <div className="absolute top-full mt-3 bg-white border border-gray-200 rounded-md shadow-lg w-48">
                <ul className="py-2">
                  <li className="px-6 py-2 hover:bg-gray-100 hover:text-blue-600">
                    <Link to="/product/caresnippet">CareSnippet</Link>
                  </li>
                  <li className="px-6 py-2 hover:bg-gray-100 hover:text-blue-600">
                    <Link to="/product/genrx">GenRx</Link>
                  </li>
                  <li className="px-6 py-2 hover:bg-gray-100 hover:text-blue-600">
                    <Link to="/product/agentrx">AgentRx</Link>
                  </li>
                  <li className="px-6 py-2 hover:bg-gray-100 hover:text-blue-600">
                    <Link to="/product/carerlab">CarerLab</Link>
                  </li>
                  <li className="px-6 py-2 hover:bg-gray-100 hover:text-blue-600">
                    <Link to="/product/carerrx">CarerRx</Link>
                  </li>
                </ul>
              </div>
            )}
          </li>
          <li className="text-gray-900 hover:text-blue-600 py-2 md:py-0">
            <Link to="/agent">Agentic Consulting</Link>
          </li>
          <li className="text-gray-900 hover:text-blue-600 py-2 md:py-0">
            <Link to="/blog">Learn</Link>
          </li>
          <li className="text-gray-900 hover:text-blue-600 py-2 md:py-0">
            <Link to="/about">About Us</Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;