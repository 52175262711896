import React, { useEffect, useState } from 'react';
import Product from './Product';
import { Link } from 'react-router-dom';


const Hero: React.FC = () => {
  const text = "Elevating Health Knowledge";
  const [displayedText, setDisplayedText] = useState('');
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (index < text.length) {
        setDisplayedText((prev) => prev + text[index]);
        setIndex(index + 1);
      } else {
        setTimeout(() => {
          setDisplayedText('');
          setIndex(0);
        }, 250);
      }
    }, 200);

    return () => clearInterval(interval);
  }, [index, text]);

  return (
    <>
      {/* Hero Section */}
      <section
        className="h-[50vh] w-full flex items-center bg-cover bg-center bg-no-repeat text-white bg-gray-100"
        style={{ backgroundImage: `url('/Myimages/Home image.jpg')` }}
      >
        <div className="p-8 text-left text-black">
          <h1 className="text-3xl md:text-4xl font-bold">
            Empowering Care and <span className="text-indigo-500">{displayedText}</span>
          </h1>
          <p className="mt-4 text-lg md:text-xl">
            Bringing you closer to the knowledge that supports your wellness journey.
          </p>
        </div>
         
         
        <Link to="https://wa.me/919289228422" target="_blank" rel="noopener noreferrer">
        <img
          src="/whatsapp logo2.webp"
          alt="WhatsApp"
          className="absolute bottom-[-120px]  right-4 w-12 h-12 md:w-13 md:h-13 object-contain bg-green-500 rounded-full"
        />
        </Link>
      </section>

      {/* Product Page Content Section */}
      <section className="h-[60vh] w-full flex items-center justify-center bg-gray-100">
        <Product />
      </section>
      
      
    </>
  );
};

export default Hero;
