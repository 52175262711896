import React from 'react';

const About = () => {
  return (
    <div className="max-w-full mx-auto px-4 py-12 bg-gray-100">
      <section className="text-center mb-12">
        <h1 className="text-3xl font-bold text-gray-800 mb-6 text-left md:text-center ml-6 md:ml-0">
          At Carer, we are committed to transforming healthcare
        </h1>
        <p className="text-xl text-blue-500 font-medium mx-6 md:mx-20 text-left">
          By creating a seamless connection between patients, doctors, and personalized medical insights. Our mission is to empower healthcare communication, elevate knowledge, and boost efficiency, leading to improved patient outcomes and enhanced care delivery.
        </p>
      </section>

      {/* What We Do Section */}
      <section className="mb-12">
        <h2 className="text-2xl font-semibold text-gray-800 text-center mb-8">
          What We Do
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mx-6 md:mx-10">
          <div className="p-6 border rounded-lg shadow-md bg-gradient-to-r from-blue-400 to-indigo-400">
            <h3 className="text-xl font-semibold text-gray-800 mb-4">
              Empowering Patient Communication
            </h3>
            <p className="text-gray-700">
              We enable clear, timely interactions that foster proactive healthcare decisions.
            </p>
          </div>
          <div className="p-6 border rounded-lg shadow-md bg-gradient-to-r from-blue-400 to-indigo-400">
            <h3 className="text-xl font-semibold text-gray-800 mb-4">
              Elevating Knowledge
            </h3>
            <p className="text-gray-700">
              Our platform enhances medical understanding for patients and doctors with up-to-date insights.
            </p>
          </div>
          <div className="p-6 border rounded-lg shadow-md bg-gradient-to-r from-blue-400 to-indigo-400">
            <h3 className="text-xl font-semibold text-gray-800 mb-4">
              Boosting Doctor Productivity
            </h3>
            <p className="text-gray-700">
              We streamline workflows, enabling doctors to focus more on care delivery.
            </p>
          </div>
          <div className="p-6 border rounded-lg shadow-md bg-gradient-to-r from-blue-400 to-indigo-400">
            <h3 className="text-xl font-semibold text-gray-800 mb-4">
              Delivering Personalized Care
            </h3>
            <p className="text-gray-700">
              We offer tailored solutions that ensure individualized, effective patient care.
            </p>
          </div>
          <div className="p-6 border rounded-lg shadow-md bg-gradient-to-r from-blue-400 to-indigo-500">
            <h3 className="text-xl font-semibold text-gray-800 mb-4">
              CareAgentic
            </h3>
            <p className="text-gray-700">
              Transforming healthcare with GenAI-powered consulting for enhanced patient communication, internal knowledge, and productivity.
            </p>
          </div>
        </div>
      </section>

      {/* Solutions Section */}
      <section className="mb-12">
        <h2 className="text-2xl font-semibold text-gray-800 text-center mb-8">
          Our Different Solutions
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mx-6 md:mx-10">
          <div className="p-6 border rounded-lg shadow-md bg-gradient-to-r from-cyan-500 to-blue-500">
            <h3 className="text-xl font-semibold text-gray-800 mb-4">
              Presage
            </h3>
            <p className="text-gray-700">
              For patient insights.
            </p>
          </div>
          <div className="p-6 border rounded-lg shadow-md bg-gradient-to-r from-cyan-500 to-blue-500">
            <h3 className="text-xl font-semibold text-gray-800 mb-4">
              Plex
            </h3>
            <p className="text-gray-700">
              For knowledge management.
            </p>
          </div>
          <div className="p-6 border rounded-lg shadow-md bg-gradient-to-r from-cyan-500 to-blue-500">
            <h3 className="text-xl font-semibold text-gray-800 mb-4">
              Progressive
            </h3>
            <p className="text-gray-700">
              For expertise growth.
            </p>
          </div>
          <div className="p-6 border rounded-lg shadow-md bg-gradient-to-r from-cyan-500 to-blue-500">
            <h3 className="text-xl font-semibold text-gray-800 mb-4">
              Productivity
            </h3>
            <p className="text-gray-700">
              For task automation.
            </p>
          </div>
          <div className="p-6 border rounded-lg shadow-md bg-gradient-to-r from-cyan-500 to-blue-500">
            <h3 className="text-xl font-semibold text-gray-800 mb-4">
              Personalization
            </h3>
            <p className="text-gray-700">
              For tailored content delivery.
            </p>
          </div>
        </div>
      </section>


      <section className="text-left">
        <p className="text-lg text-blue-700 mx-auto ml-6 md:ml-10">
          At Carer, we are not just a healthcare company—we are a partner in better health, committed to making healthcare more connected, efficient, and patient-centered.
        </p>
      </section>
    </div>
  );
};

export default About;
