import React from 'react';
import Navbar from './Dashboard/Pages/Navbar';
import Hero from './Dashboard/Pages/Hero';
import Footer from './Dashboard/Pages/Footer';
import About from './Dashboard/Pages/About';
import  Agent  from './Dashboard/Pages/Agent';
import ProductPage from './Dashboard/Pages/Product';
import Blog from './Dashboard/Pages/Blog'
import { Routes, Route } from 'react-router-dom';
import CaresnippetPage from './Dashboard/Product/Carepage';
import GenRxPage from './Dashboard/Product/Genrxpage';
import AgentRxPage from './Dashboard/Product/Agent_Rx_Page';
import Carerlab from './Dashboard/Product/Carelab';
import CarerRx from './Dashboard/Product/carer_Rx'
//import {  Routes,Route } from 'react-router-dom';
const App: React.FC = () => {
  return (
  
    <>
      <Navbar />
      <div className="min-h-screen">
        <Routes>
          <Route path="/" element={<Hero />} />
          <Route path="/product" element={<ProductPage />} />
          <Route path="/about" element={<About />} />
          <Route path="/agent" element={<Agent />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/product/caresnippet" element= {<CaresnippetPage />} />
          <Route path="/product/genrx" element= {<GenRxPage />} />
          <Route path="/product/agentrx" element= {<AgentRxPage />} />
          <Route path="/product/carerlab" element= {<Carerlab />} />
          <Route path="/product/carerrx" element= {<CarerRx />} />
        </Routes>
      </div>
      <Footer />
    </>
  );
}

export default App;
