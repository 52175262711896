import React, { useEffect, useRef, useState } from 'react';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import { Link } from 'react-router-dom';

const products = [
  {
    title: "Caresnippet",
    description: "It is designed to save doctors' precious time, boost productivity, and enhance their medical expertise – all at their fingertips.",
    link: "/product/caresnippet",
  },
  {
    title: "GenRx",
    description: "It simplifies healthcare, making it affordable and accessible for everyone.",
    link: "/product/genrx",
  },
  {
    title: "AgentRx",
    description: "It empowers patients to take charge of their health with actionable insights.",
    link: "/product/agentrx",
  },
  {
    title: "CarerLab",
    description: "Enhance Precision, Build Patient Trust, and Boost Productivity – Empower Your Lab with CarerLab",
    link: "/product/carerlab",
  },
  {
    title: "CarerRx",
    description: "Enhance Service, Build Customer Trust, and Boost Efficiency – Empower your Pharmacy with CarerRx",
    link: "/product/carerrx",
  },
];

const ProductPage = () => {
  const carouselRef = useRef<HTMLDivElement | null>(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false);

  const extendedProducts = [...products, ...products, ...products];

  useEffect(() => {
    if (isPaused) return;

    const interval = setInterval(() => {
      if (carouselRef.current) {
        const newIndex = (activeIndex + 1) % products.length;
        setActiveIndex(newIndex);

        const element = carouselRef.current as HTMLDivElement;
        const scrollPosition = newIndex * element.offsetWidth;
        element.scrollTo({
          left: scrollPosition,
          behavior: 'smooth'
        });
      }
    }, 3000);

    return () => clearInterval(interval);
  }, [activeIndex, isPaused]);

  const handleNavigation = (direction: 'prev' | 'next') => {
    if (carouselRef.current) {
      const newIndex = direction === 'next'
        ? (activeIndex + 1) % products.length
        : (activeIndex - 1 + products.length) % products.length;

      setActiveIndex(newIndex);

      const element = carouselRef.current as HTMLDivElement;
      const scrollPosition = newIndex * element.offsetWidth;
      element.scrollTo({
        left: scrollPosition,
        behavior: 'smooth'
      });
    }
  };

  return (
    <div className="p-6 min-h-screen flex flex-col items-center justify-center overflow-y-hidden">
      <h1 className="text-2xl md:text-4xl font-bold text-center mb-8 text-gray-800">Our Products</h1>

      <div className="relative w-full max-w-full md:max-w-3xl lg:max-w-6xl px-4 md:px-20">
        <button
          onClick={() => handleNavigation('prev')}
          className="absolute left-2 md:left-0 top-1/2 -translate-y-1/2 z-10 bg-white p-3 md:p-4 rounded-full shadow-lg hover:bg-gray-100 transition-colors hidden md:block"
        >
          <AiOutlineLeft size={20} />
        </button>

        <div
          className="relative w-full"
          onMouseEnter={() => setIsPaused(true)}
          onMouseLeave={() => setIsPaused(false)}
        >
          <div
            ref={carouselRef}
            className="overflow-hidden"
          >
            <div className="flex transition-transform duration-500">
              {extendedProducts.map((product, index) => (
                <div
                  key={index}
                  className="flex-none w-full md:w-full px-2 md:px-4"
                >
                  <div className="bg-white p-4 md:p-6 rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-200 h-64 md:h-72 mx-auto max-w-xs md:max-w-2xl flex flex-col">
                    <h2 className="text-lg md:text-2xl font-semibold mb-2 md:mb-4 text-center text-gray-800">
                      {product.title}
                    </h2>
                    <p className="text-sm md:text-base text-gray-700 font-semibold leading-relaxed text-center mt-2 md:mt-4">
                      {product.description}
                    </p>
                    <div className="mt-10 md:mt-8 text-center">
                      <Link
                        to={product.link}
                        className="inline-block py-2 px-4 md:px-6 bg-blue-500 text-white rounded-lg hover:bg-blue-800 transition"
                      >
                        Click here for more
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <button
          onClick={() => handleNavigation('next')}
          className="absolute right-2 md:right-0 top-1/2 -translate-y-1/2 z-10 bg-white p-3 md:p-4 rounded-full shadow-lg hover:bg-gray-100 transition-colors hidden md:block"
        >
          <AiOutlineRight size={20} />
        </button>

        <div className="flex justify-center mt-6 space-x-2">
          {products.map((_, index) => (
            <button
              key={index}
              className={`w-2 h-2 md:w-3 md:h-3 rounded-full transition-colors ${
                index === activeIndex ? 'bg-blue-600' : 'bg-gray-300'
              }`}
              onClick={() => setActiveIndex(index)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProductPage;
